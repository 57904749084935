import { useState, useEffect } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Footer } from '../components/Footer';
import { HeaderLogged } from '../components/HeaderLogged';
import { useLocalStorage } from '../utils/localStorage';
import { WalletSelection } from '../components/WalletSelection';
import { ExchangeModal } from '../components/ExchangeModal';
import { ExchangeStockModal } from '../components/ExchangeStockModal';

export function Wallet() {
  const [walletName, setWalletName] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [walletDetails, setWalletDetails] = useState([]);
  const [stockWalletDetails] = useState([]);
  const [stakeWalletDetails] = useState([]);
  const [currentCoin, setCurrentCoin] = useState({ coinId: 0, price: 0 });
  const [exchangeOpen, setExchangeOpen] = useState(false);
  const [currentStock, setCurrentStock] = useState({});
  const [stockOpen, setStockOpen] = useState(false);
  const [tradeHistory] = useState([]);
  const [marketPrice, setMarketPrice] = useState(true);
  const [tradePrice, setTradePrice] = useState('0');
  const selectedWallet = useSelector((state) => state.selectedWallet);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2c3e50',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleClose = () => {
    setWalletName('');
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleStockOpen = (stockSymbol, stockPrice, stockName) => {
    setCurrentStock({
      stockName,
      stockPrice,
      stockSymbol,
    });
    setStockOpen(true);
  };

  const handleStockClose = () => {
    setMarketPrice(true);
    setTradePrice(0);
    setStockOpen(false);
  };

  const createNewWallet = async () => {
    setLoading(true);
    await axios.post('https://api.cryptocricket.win/wallet/new', {
      walletName,
      userId: useLocalStorage('userId'),
    });
    setLoading(false);
    handleClose();
  };

  const handleExchangeOpen = (coinId, price, name, symbol, slug) => {
    setCurrentCoin({
      coinId, price, name, symbol, slug,
    });
    setExchangeOpen(true);
  };

  const handleExchangeClose = () => {
    console.log('handleClose');
    setMarketPrice(true);
    setTradePrice(0);
    setExchangeOpen(false);
  };

  const coinPrices = {
    BNB: 580.02,
    SOL: 143.90,
    USDT: 0.9995,
    USDC: 0.9999,
    BTC: 62203.21,
    ETH: 2440.94,
    XRP: 0.5305,
    TON: 5.21,
    ADA: 0.344,
    TRX: 0.1603,
    AVAX: 26.39,
    DOT: 4.10,
    LINK: 10.83,
    BCH: 329.04,
    NEAR: 4.90,
    LTC: 65.73,
    MATIC: 0.377,
    UNI: 7.20,
  };

  const wallets = [
    {
      id: 22,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: coinPrices.USDT, coinAmount: 199706.68, coinAmountUSD: 199706.68, coinSlug: 'usdt',
        },
        {
          coinId: 4, coinName: 'BNB', coinSymbol: 'BNB', coinPrice: coinPrices.BNB, coinAmount: 2.0, coinAmountUSD: 1029.68, coinSlug: 'bnb',
        },
        {
          coinId: 5, coinName: 'Solana', coinSymbol: 'SOL', coinPrice: coinPrices.SOL, coinAmount: 5, coinAmountUSD: 768.7, coinSlug: 'sol',
        },
        {
          coinId: 6, coinName: 'USD Coin', coinSymbol: 'USDC', coinPrice: coinPrices.USDC, coinAmount: 2000, coinAmountUSD: 1999.8, coinSlug: 'usdc',
        },
        {
          coinId: 7, coinName: 'XRP', coinSymbol: 'XRP', coinPrice: coinPrices.XRP, coinAmount: 4000, coinAmountUSD: 2397.2, coinSlug: 'xrp',
        },
        {
          coinId: 8, coinName: 'Toncoin', coinSymbol: 'TON', coinPrice: coinPrices.TON, coinAmount: 200, coinAmountUSD: 1328, coinSlug: 'ton',
        },
        {
          coinId: 9, coinName: 'Cardano', coinSymbol: 'ADA', coinPrice: coinPrices.ADA, coinAmount: 3000, coinAmountUSD: 1035.9, coinSlug: 'ada',
        },
        {
          coinId: 10, coinName: 'TRON', coinSymbol: 'TRX', coinPrice: coinPrices.TRX, coinAmount: 10000, coinAmountUSD: 1294, coinSlug: 'trx',
        },
        {
          coinId: 11, coinName: 'Avalanche', coinSymbol: 'AVAX', coinPrice: coinPrices.AVAX, coinAmount: 50, coinAmountUSD: 1079, coinSlug: 'avax',
        },
        {
          coinId: 12, coinName: 'Polkadot', coinSymbol: 'DOT', coinPrice: coinPrices.DOT, coinAmount: 500, coinAmountUSD: 2370, coinSlug: 'dot',
        },
        {
          coinId: 13, coinName: 'Chainlink', coinSymbol: 'LINK', coinPrice: coinPrices.LINK, coinAmount: 100, coinAmountUSD: 1056, coinSlug: 'link',
        },
        {
          coinId: 14, coinName: 'Bitcoin Cash', coinSymbol: 'BCH', coinPrice: coinPrices.BCH, coinAmount: 10, coinAmountUSD: 3580.5, coinSlug: 'bch',
        },
        {
          coinId: 15, coinName: 'NEAR Protocol', coinSymbol: 'NEAR', coinPrice: coinPrices.NEAR, coinAmount: 300, coinAmountUSD: 1209, coinSlug: 'near',
        },
        {
          coinId: 16, coinName: 'Litecoin', coinSymbol: 'LTC', coinPrice: coinPrices.LTC, coinAmount: 50, coinAmountUSD: 3058, coinSlug: 'ltc',
        },
        {
          coinId: 17, coinName: 'Polygon', coinSymbol: 'MATIC', coinPrice: coinPrices.MATIC, coinAmount: 4000, coinAmountUSD: 1710.8, coinSlug: 'matic',
        },
        {
          coinId: 18, coinName: 'Uniswap', coinSymbol: 'UNI', coinPrice: coinPrices.UNI, coinAmount: 200, coinAmountUSD: 1240, coinSlug: 'uni',
        },
      ],
    },
    {
      id: 23,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: coinPrices.USDT, coinAmount: 100116.33, coinAmountUSD: 110216.33, coinSlug: 'usdt',
        },
        {
          coinId: 3, coinName: 'Ethereum', coinSymbol: 'ETH', coinPrice: coinPrices.ETH, coinAmount: 1, coinAmountUSD: 2617.37, coinSlug: 'eth',
        },
        {
          coinId: 4, coinName: 'BNB', coinSymbol: 'BNB', coinPrice: coinPrices.BNB, coinAmount: 23, coinAmountUSD: 514.84, coinSlug: 'bnb',
        },
        {
          coinId: 5, coinName: 'Solana', coinSymbol: 'SOL', coinPrice: coinPrices.SOL, coinAmount: 9, coinAmountUSD: 1153.74, coinSlug: 'sol',
        },
        {
          coinId: 7, coinName: 'XRP', coinSymbol: 'XRP', coinPrice: coinPrices.XRP, coinAmount: 2500, coinAmountUSD: 1198.6, coinSlug: 'xrp',
        },
        {
          coinId: 8, coinName: 'Toncoin', coinSymbol: 'TON', coinPrice: coinPrices.TON, coinAmount: 1200, coinAmountUSD: 6664, coinSlug: 'ton',
        },
        {
          coinId: 9, coinName: 'Cardano', coinSymbol: 'ADA', coinPrice: coinPrices.ADA, coinAmount: 30000, coinAmountUSD: 6900.6, coinSlug: 'ada',
        },
        {
          coinId: 10, coinName: 'TRON', coinSymbol: 'TRX', coinPrice: coinPrices.TRX, coinAmount: 5000, coinAmountUSD: 647, coinSlug: 'trx',
        },
        {
          coinId: 11, coinName: 'Avalanche', coinSymbol: 'AVAX', coinPrice: coinPrices.AVAX, coinAmount: 100, coinAmountUSD: 2158, coinSlug: 'avax',
        },
        {
          coinId: 13, coinName: 'Chainlink', coinSymbol: 'LINK', coinPrice: coinPrices.LINK, coinAmount: 100, coinAmountUSD: 1056, coinSlug: 'link',
        },
        {
          coinId: 16, coinName: 'Litecoin', coinSymbol: 'LTC', coinPrice: coinPrices.LTC, coinAmount: 110, coinAmountUSD: 6100, coinSlug: 'ltc',
        },
        {
          coinId: 17, coinName: 'Polygon', coinSymbol: 'MATIC', coinPrice: coinPrices.MATIC, coinAmount: 1000, coinAmountUSD: 427.7, coinSlug: 'matic',
        },
        {
          coinId: 18, coinName: 'Uniswap', coinSymbol: 'UNI', coinPrice: coinPrices.UNI, coinAmount: 200, coinAmountUSD: 1240, coinSlug: 'uni',
        },
      ],
    },
    {
      id: 24,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: coinPrices.USDT, coinAmount: 284219.20, coinAmountUSD: 284219.20, coinSlug: 'usdt',
        },
        {
          coinId: 2, coinName: 'Bitcoin', coinSymbol: 'BTC', coinPrice: coinPrices.BTC, coinAmount: 0.05, coinAmountUSD: 3032.67, coinSlug: 'btc',
        },
        {
          coinId: 3, coinName: 'Ethereum', coinSymbol: 'ETH', coinPrice: coinPrices.ETH, coinAmount: 1.0, coinAmountUSD: 2617.37, coinSlug: 'eth',
        },
        {
          coinId: 4, coinName: 'BNB', coinSymbol: 'BNB', coinPrice: coinPrices.BNB, coinAmount: 3.5, coinAmountUSD: 1801.94, coinSlug: 'bnb',
        },
        {
          coinId: 5, coinName: 'Solana', coinSymbol: 'SOL', coinPrice: coinPrices.SOL, coinAmount: 10, coinAmountUSD: 1537.4, coinSlug: 'sol',
        },
        {
          coinId: 11, coinName: 'Avalanche', coinSymbol: 'AVAX', coinPrice: coinPrices.AVAX, coinAmount: 100, coinAmountUSD: 2158, coinSlug: 'avax',
        },
        {
          coinId: 15, coinName: 'NEAR Protocol', coinSymbol: 'NEAR', coinPrice: coinPrices.NEAR, coinAmount: 2000, coinAmountUSD: 8060, coinSlug: 'near',
        },
        {
          coinId: 16, coinName: 'Litecoin', coinSymbol: 'LTC', coinPrice: coinPrices.LTC, coinAmount: 100, coinAmountUSD: 6116, coinSlug: 'ltc',
        },
        {
          coinId: 18, coinName: 'Uniswap', coinSymbol: 'UNI', coinPrice: coinPrices.UNI, coinAmount: 500, coinAmountUSD: 3100, coinSlug: 'uni',
        },
      ],
    },

    {
      id: 25,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: 1.00, coinAmount: 412889.77, coinAmountUSD: 412889.77, coinSlug: 'usdt',
        },
      ],
    },

    {
      id: 26,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: 1.00, coinAmount: 26881.44, coinAmountUSD: 26881.44, coinSlug: 'usdt',
        },
      ],
    },

    {
      id: 27,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: 1.00, coinAmount: 538997.63, coinAmountUSD: 538997.63, coinSlug: 'usdt',
        },
      ],
    },

    {
      id: 28,
      assets: [
        {
          coinId: 1, coinName: 'Tether', coinSymbol: 'USDT', coinPrice: 1.00, coinAmount: 6236.74, coinAmountUSD: 6236.74, coinSlug: 'usdt',
        },
      ],
    },
  ];

  useEffect(() => {
    setLoading(true);
    setWalletDetails(wallets.find((wallet) => wallet.id === selectedWallet)?.assets || []);
    setLoading(false);
    // axios.post('https://api.cryptocricket.win/wallet/coins-by-wallet/', {
    //   walletId: selectedWallet,
    // })
    //   .then((response) => {
    //     const walletInfo = response.data;
    //     axios.get('https://api.cryptocricket.win/cmc/all')
    //       .then((coins) => {
    //         const prices = coins.data.data;
    //         const walletWithPrices = prices.reduce((acc, curr) => {
    //           const isCoinOnWallet = walletInfo.find((coin) => coin.coinId === curr.id);
    //           if (isCoinOnWallet) {
    //             acc = [...acc, {
    //               coinId: curr.id,
    //               coinName: curr.name,
    //               coinSymbol: curr.symbol,
    //               coinPrice: curr.quote.USD.price,
    //               coinAmount: isCoinOnWallet.coinAmount,
    //               coinAmountUSD: isCoinOnWallet.coinAmount * curr.quote.USD.price,
    //               coinSlug: curr.slug,
    //             }];
    //             return acc;
    //           }
    //           return acc;
    //         }, []);
    //         setWalletDetails(walletWithPrices.sort((a, b) => b.coinAmountUSD - a.coinAmountUSD));
    //       });
    //   });
    // axios.post('https://api.cryptocricket.win/wallet/stocks-by-wallet/', {
    //   walletId: selectedWallet,
    // })
    //   .then((response) => {
    //     const stockWalletInfo = response.data;
    //     axios.get('https://api.cryptocricket.win/stocks/all')
    //       .then((stocks) => {
    //         const prices = stocks.data;
    //         const stockWalletWithPrices = prices.reduce((acc, curr) => {
    //           const isStockOnWallet = stockWalletInfo
    //             .find((stock) => stock.stockSymbol === curr.stockSymbol);
    //           if (isStockOnWallet) {
    //             acc = [...acc, {
    //               stockSymbol: curr.stockSymbol,
    //               stockName: curr.stockName,
    //               stockPrice: curr.stockPrice,
    //               stockAmount: isStockOnWallet.stockAmount,
    //               stockAmountUSD: isStockOnWallet.stockAmount * curr.stockPrice,
    //             }];
    //             return acc;
    //           }
    //           return acc;
    //         }, []);
    //         setStockWalletDetails(stockWalletWithPrices
    //           .sort((a, b) => b.stockAmountUSD - a.stockAmountUSD));
    //       });
    //   });
    // setLoading(false);
    // axios.post('https://api.cryptocricket.win/wallet/stake-by-wallet', {
    //   walletId: selectedWallet,
    // })
    //   .then((response) => {
    //     setStakeWalletDetails(response.data);
    //   });
    // axios.post('https://api.cryptocricket.win/exchange/history', {
    //   walletId: selectedWallet,
    // })
    //   .then((response) => {
    //     setTradeHistory(response.data);
    //   });
  }, [selectedWallet]);

  useEffect(() => {
    console.log(tradeHistory);
  }, [tradeHistory]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const tradeHistoryFullfilled = tradeHistory.filter((trade) => trade.tradeStatus === 'fullfilled');
  const tradeHistoryPending = tradeHistory.filter((trade) => trade.tradeStatus === 'pending');

  return (
    <div>
      <HeaderLogged />
      <div className="wallet-container">
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <h1>Wallet</h1>
            {' '}
            <Box marginLeft={1} sx={{ cursor: 'pointer' }}>
              <AddBoxIcon
                onClick={handleOpen}
                fontSize="large"
              />
            </Box>
          </Box>
          <WalletSelection />
        </Box>
        <h2>Crypto Assets</h2>
        {loading && (
        <Box justifyContent="center" sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
        )}
        <h4>
          Value:
          {' '}
          $
          {' '}
          {Number(walletDetails.reduce((acc, curr) => {
            const coinAmountUSD = curr.coinAmount * curr.coinPrice;
            acc += coinAmountUSD;
            return acc;
          }, 0).toFixed(2)).toLocaleString('en-US')}
        </h4>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Coin Name</StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">USD Value</StyledTableCell>
                <StyledTableCell align="center">Exchange</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletDetails.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.coinName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $
                    {' '}
                    {row.coinPrice}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.coinAmount}
                    {' '}
                    {row.coinSymbol}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $
                    {Number((row.coinPrice * row.coinAmount).toFixed(2)).toLocaleString('en-US')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => handleExchangeOpen(row
                        .coinId, row.coinPrice, row.coinName, row.coinSymbol, row.coinSlug)}
                    >
                      Exchange
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <h2>Stock Assets</h2>
        <h4>
          Value:
          {' '}
          $
          {' '}
          {/* {Number(stockWalletDetails.reduce((acc, curr) => {
            acc += curr.stockAmountUSD;
            return acc;
          }, 0).toFixed(2)).toLocaleString('en-US')} */}
          0
        </h4>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Stock Name</StyledTableCell>
                <StyledTableCell align="center">Price</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">USD Value</StyledTableCell>
                <StyledTableCell align="center">Exchange</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockWalletDetails.map((row) => (
                <StyledTableRow key={uuidv4()}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.stockName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $
                    {' '}
                    {row.stockPrice}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stockAmount}
                    {' '}
                    {row.stockSymbol}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $
                    {Number(row.stockAmountUSD.toFixed(2)).toLocaleString('en-US')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => handleStockOpen(row
                        .stockSymbol, row.stockPrice, row.stockName)}
                    >
                      Exchange
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <br />
        <h2>Currently Staking</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Asset Name</StyledTableCell>
                <StyledTableCell align="center">Asset Symbol</StyledTableCell>
                <StyledTableCell align="center">Asset Type</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Stake Duration</StyledTableCell>
                <StyledTableCell align="center">APY</StyledTableCell>
                <StyledTableCell align="center">Stake Date</StyledTableCell>
                <StyledTableCell align="center">New Amount</StyledTableCell>
                <StyledTableCell align="center">Stake Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stakeWalletDetails.map((row) => (
                <StyledTableRow key={uuidv4()}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.stakeName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakeSymbol}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakeType}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakeAmount}
                    {' '}
                    {row.stakeSymbol}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakePeriod}
                    {' '}
                    days
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakeApy.toFixed(2)}
                    {' '}
                    %
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.stakeDate.split('T')[0]}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {(row.stakeAmount + (row.stakeAmount * (
                      ((row.stakeApy / 360) * row.stakePeriod) / 100))).toFixed(2)}
                    {' '}
                    {row.stakeSymbol}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $
                    {Number(Number((row.stakeAmount + (row.stakeAmount * (
                      ((row.stakeApy / 360) * row.stakePeriod) / 100)))
                      * (row.stakeType === 'crypto'
                        ? walletDetails
                          .find((coin) => coin?.coinSymbol === row.stakeSymbol)?.coinPrice
                        : stockWalletDetails
                          .find((stock) => stock?.stockSymbol === row.stakeSymbol)?.stockPrice))
                      .toFixed(2)).toLocaleString('en-US')}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!stakeWalletDetails.length && 'No staking'}

        <br />
        <br />
        <h2>Trade History</h2>
        {!tradeHistoryFullfilled.length
          ? 'No trades'
          : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Symbol</StyledTableCell>
                    <StyledTableCell align="center">Type</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Value</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tradeHistoryFullfilled.map((row) => (
                    <StyledTableRow key={uuidv4()}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {row.assetName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetSymbol}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetAmount.toFixed(2)}
                        {' '}
                        {row.assetSymbol}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $
                        {row.assetPrice}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.tradeDate.split('T')[0]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $
                        {Number((row.assetAmount * row.assetPrice).toFixed(2)).toLocaleString('en-US')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.tradeType}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

        <br />
        <br />
        <h2>Waiting Fullfillment</h2>
        {!tradeHistoryPending.length
          ? 'No trades'
          : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Name</StyledTableCell>
                    <StyledTableCell align="center">Symbol</StyledTableCell>
                    <StyledTableCell align="center">Type</StyledTableCell>
                    <StyledTableCell align="center">Amount</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Value</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tradeHistoryPending.map((row) => (
                    <StyledTableRow key={uuidv4()}>
                      <StyledTableCell align="center" component="th" scope="row">
                        {row.assetName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetSymbol}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.assetAmount.toFixed(2)}
                        {' '}
                        {row.assetSymbol}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $
                        {row.assetPrice}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.tradeDate.split('T')[0]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $
                        {Number((row.assetAmount * row.assetPrice).toFixed(2)).toLocaleString('en-US')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.tradeType}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        <br />
        <br />
        <h2>Stats</h2>
        <p>Not Available in your device yet.</p>
        <br />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create a new Wallet
            </Typography>

            <Stack justifyContent="center" marginTop={2} spacing={2} direction="row">

              <TextField
                id="standard-helperText"
                helperText="Wallet Name"
                variant="standard"
                value={walletName}
                onChange={(e) => setWalletName(e.target.value)}
              />

              <Button variant="contained" color="success" onClick={createNewWallet}>{loading ? 'Creating...' : 'Create'}</Button>
              <Button variant="contained" color="error" onClick={handleClose}>Cancel</Button>
            </Stack>
          </Box>
        </Modal>

        <ExchangeModal
          open={exchangeOpen}
          handleClose={handleExchangeClose}
          currentCoin={currentCoin}
          setMarketPrice={setMarketPrice}
          marketPrice={marketPrice}
          tradePrice={tradePrice}
          setTradePrice={setTradePrice}
        />

        <ExchangeStockModal
          open={stockOpen}
          handleClose={handleStockClose}
          currentStock={currentStock}
          setMarketPrice={setMarketPrice}
          marketPrice={marketPrice}
          tradePrice={tradePrice}
          setTradePrice={setTradePrice}
        />
      </div>
      <Footer />
    </div>
  );
}
